:root {
  --zoom: 0.9;
}

#root {
  overflow: hidden;
}

.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

/* .spinner {
  background-color: #fff;
  display: flex;
  height: 100vh;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
} */

.result-block-container .result-block {
  opacity: 1;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #C6C6C6; 
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.bank-headsup {
  list-style: none;
  padding-left: 0;
}

.bank-headsup li {
  position: relative;
  padding-left: 40px;
  margin-top: 15px;
}

.bank-headsup li::before {
  position: absolute;
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.9999 20.1673C16.0625 20.1673 20.1666 16.0633 20.1666 11.0007C20.1666 5.93804 16.0625 1.83398 10.9999 1.83398C5.93731 1.83398 1.83325 5.93804 1.83325 11.0007C1.83325 16.0633 5.93731 20.1673 10.9999 20.1673Z' fill='%233E9AEA'/%3E%3Cpath d='M9.69832 14.2811C9.51499 14.2811 9.34082 14.2078 9.21249 14.0795L6.61832 11.4853C6.35249 11.2195 6.35249 10.7795 6.61832 10.5136C6.88415 10.2478 7.32415 10.2478 7.58999 10.5136L9.69832 12.622L14.41 7.91031C14.6758 7.64448 15.1158 7.64448 15.3817 7.91031C15.6475 8.17615 15.6475 8.61615 15.3817 8.88198L10.1842 14.0795C10.0558 14.2078 9.88165 14.2811 9.69832 14.2811Z' fill='white'/%3E%3C/svg%3E%0A");
  width: 21px;
  height: 21px;
  left: 10px;
}

.received-msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 100%;
  margin: 15px 0;
}

.received-withd-msg .message-body {
  position: relative;
  background-color: #F4F4F8;
  color: #182A39;
  margin-left: 60px;
  padding: 15px 20px;
  border-radius: 20px 20px 20px 0px;
  text-align: left;
}

.received-withd-msg .time-stamp {
  position: absolute;
  left: 60px;
  margin-top: 10px;
  font-size: 12px;
}

.received-withd-msg {
  width: 75%;
}

.outgoing-msg {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  margin: 15px 0;
  width: 100%;
}

.sent-msg {
  width: 75%;
  margin: 15px 0;
}

.sent-msg .message-body {
  position: relative;
  background-color: #EBF5FF;
  color: #182A39;
  padding: 15px 20px;
  border-radius: 20px 20px 20px 0px;
  text-align: left;
}

.sent-msg .time-stamp {
  position: absolute;
  left: 0;
  margin-top: 10px;
  font-size: 12px;
}

.message-input {
  flex: 1;
  margin-right: 20px;
  border: none;
  outline: none;
}

.image-bounce {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: image-bounce;
  animation-timing-function: linear;
}
@keyframes image-bounce {
  0%   { transform: translateY(0); }
  50%  { transform: translateY(-20px); }
  100% { transform: translateY(0); }
}

.expiration-date-picker .MuiFormControl-root .MuiOutlinedInput-root fieldset legend {
  display: none;
}


/* Register Page */
.registerContainer div span {
  margin: 15px 0 5px;
}

.registerContainer h1 {
  margin-bottom: 10px;
}

.MuiToggleButtonGroup-root .MuiToggleButton-root {
  background-color: #8FA1B0;
  color: #FFF;
  text-transform: none;
  border: solid 1px #5FA9E9 !important;
  margin-right: 2em;
  border-radius: 8px !important;
  padding: 5px 10px;
}

.MuiToggleButtonGroup-root .MuiToggleButton-root:hover,
.MuiToggleButtonGroup-root .MuiToggleButton-root.Mui-selected:hover {
  color: #5FA9E9;
}

.MuiToggleButtonGroup-root .MuiToggleButton-root.Mui-selected {
  background-color: #3E9AEA;
  color: #FFF;
  border: solid 3px #347EBE !important;
}