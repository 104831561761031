@import url('./App.css');

@font-face {
  font-family: 'BiennaleBlackIt';
  src: url('./assets/fonts/Biennale/Biennale\ Black\ It.ttf');
}

@font-face {
  font-family: 'BiennaleBlack';
  src: url('./assets/fonts/Biennale/Biennale\ Black.ttf');
}

@font-face {
  font-family: 'BiennaleBoldIt';
  src: url('./assets/fonts/Biennale/Biennale\ Bold\ It.ttf');
}

@font-face {
  font-family: 'BiennaleBold';
  src: url('./assets/fonts/Biennale/Biennale\ Bold.ttf');
}

@font-face {
  font-family: 'BiennaleBookIt';
  src: url('./assets/fonts/Biennale/Biennale\ Book\ It.ttf');
}

@font-face {
  font-family: 'BiennaleBook';
  src: url('./assets/fonts/Biennale/Biennale\ Book.ttf');
}

@font-face {
  font-family: 'BiennaleHairIt';
  src: url('./assets/fonts/Biennale/Biennale\ Hair\ It.ttf');
}

@font-face {
  font-family: 'BiennaleHair';
  src: url('./assets/fonts/Biennale/Biennale\ Hair.ttf');
}

@font-face {
  font-family: 'BiennaleHeavyIt';
  src: url('./assets/fonts/Biennale/Biennale\ Heavy\ It.ttf');
}

@font-face {
  font-family: 'BiennaleHeavy';
  src: url('./assets/fonts/Biennale/Biennale\ Heavy.ttf');
}

@font-face {
  font-family: 'BiennaleLightIt';
  src: url('./assets/fonts/Biennale/Biennale\ Light\ It.ttf');
}

@font-face {
  font-family: 'BiennaleLight';
  src: url('./assets/fonts/Biennale/Biennale\ Light.ttf');
}

@font-face {
  font-family: 'BiennaleMediumIt';
  src: url('./assets/fonts/Biennale/Biennale\ Medium\ It.ttf');
}

@font-face {
  font-family: 'BiennaleMedium';
  src: url('./assets/fonts/Biennale/Biennale\ Medium.ttf');
}

@font-face {
  font-family: 'BiennaleRegularIt';
  src: url('./assets/fonts/Biennale/Biennale\ Regular\ It.ttf');
}

@font-face {
  font-family: 'BiennaleRegular';
  src: url('./assets/fonts/Biennale/Biennale\ Regular.ttf');
}

@font-face {
  font-family: 'BiennaleSemiBoldIt';
  src: url('./assets/fonts/Biennale/Biennale\ SemiBold\ It.ttf');
}

@font-face {
  font-family: 'BiennaleSemiBold';
  src: url('./assets/fonts/Biennale/Biennale\ SemiBold.ttf');
}

@font-face {
  font-family: 'BiennaleThinIt';
  src: url('./assets/fonts/Biennale/Biennale\ Thin\ It.ttf');
}

@font-face {
  font-family: 'BiennaleThin';
  src: url('./assets/fonts/Biennale/Biennale\ Thin.ttf');
}

@font-face {
  font-family: 'BiennaleUltraLightIt';
  src: url('./assets/fonts/Biennale/Biennale\ UltraLight\ It.ttf');
}

@font-face {
  font-family: 'BiennaleUltraLight';
  src: url('./assets/fonts/Biennale/Biennale\ Ultra\ Light.ttf');
}

body {
  background-color: rgb(242, 244, 248);
  font-family: 'BiennaleRegular';
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'BiennaleRegular';
}

.toolbar {
  background-color: #1d3856;
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  width: 216px;
  z-index: 5005;
}
